@use './../../../assets/styles/variables';

.work { 
    .section-container {
        margin-bottom: 42px;
    }
}


@media (max-width: variables.$tablet-breakpoint) {

    .work {         
        .section-container {
            width: 100%;
        }
    }
}