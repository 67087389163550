@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}