@use '../../../assets/styles/variables';

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 28px;
    background-color: variables.$darkest-gray;

    img {
        height: 50px;
        margin-bottom: 32px;
    }

    span {
        color: white;
        font-size: 12px;
        margin-bottom: 10px;
    }
}
