@use './../../../assets/styles/variables';

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('./../../../assets/images/backgrounds/contact-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .header-container {    
        width: 70%;
        margin-bottom: 50px;
    }

    .social-media {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

// For tablet
@media (max-width: variables.$tablet-breakpoint) {

    .contact {         
        .header-container {
            width: 100%;
        }
    }
}