@use './../../../../../assets/styles/variables';
.section-title {
    display: flex;
    align-items: center;

    img {
        width: 28px;
        height: 28px;
        margin-right: 20px;
        color: variables.$dark-gray;
    }

    span {
        color: variables.$dark-gray;
        font-size: 22px;
        font-family: "Montserrat Medium";
    }
}