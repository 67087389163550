@use './../../../../../assets/styles/variables';

.experience-item {
    color: variables.$dark-gray;
    padding-bottom: 30px;
    border-bottom: 1px solid variables.$lightest-gray;

    .duration {
        display: inline-block;
        border: 2px solid variables.$primary-color;
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 12px;

        span {
            font-family: "Montserrat Medium";
            font-size: 14px;
            text-align: center;
        }
    }

    .content {

        span {
            font-family: "Montserrat SemiBold";
            font-size: 14px;
            color: variables.$dark-gray;
        }

        .url-style:hover {
            color: variables.$secondary-color;
            cursor: pointer;
        }

        .no-url:hover {
            cursor: not-allowed;
        }

        .link-icon {

            width: 16px;
            height: 16px;
            margin-left: 10px;
            
            path {
                fill: variables.$secondary-color;
            }
        }
        
        .job-timeline {
            margin-top: 10px;
            
            span {
                font-family: "Montserrat Medium";
                font-size: 12px;
            }

            p {
                font-family: "Montserrat";
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
}