@use './../../../../assets/styles/variables';

.experience-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 72px;
    row-gap: 28px;
}

/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {

    .experience-grid { 
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For Mobile */
@media (max-width: variables.$mobile-breakpoint) {

    .experience-grid {
        grid-template-columns: 1fr;
    }
}