@use '../../../assets/styles/variables';

.what-i-do {    
    background-image: url('./../../../assets/images/backgrounds/what-i-do-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   

    .section-header-container {
        width: 70%;
        margin-bottom: 80px;
    }
}

@media (max-width: variables.$tablet-breakpoint) {

    .what-i-do {         
        .section-header-container {
            width: 100%;
        }
    }
}