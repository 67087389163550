$primary-color: #30A76E;
$secondary-color: #6050BF;

$darkest-gray: #1E1E1E;
$dark-gray: #474747;
$medium-gray: #797979;
$gray: #adadad;
$light-gray: #EEEEEE;
$lightest-gray: #E8E8E8;
$white: '#FFFFFF';

$light-gray-semitrans: rgba(238, 238, 238, 0.15);

$dark-semitrans: rgba(255, 255, 255, 0.6);