@use './../../../../../assets/styles/variables';

.job-profile-card {
    color: white;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 44px 38px 40px 38px;
        border-radius: 12px;
        background: variables.$light-gray-semitrans;
        transition: background-color 0.3s ease-in-out;

        svg.icon {
            width: 74px;
            height: 74px;
            margin-bottom: 32px;
            path {
                fill: variables.$primary-color !important;
            }
            transition: stroke 0.3s ease-in-out;
        }
    
        span {

            font-size: 20px;
            font-family: "Montserrat SemiBold";
            margin-bottom: 30px;
        }
    
        p {
            font-size: 14px;
            font-family: "Montserrat";
            line-height: 18px;
            margin: 0;
            margin-top: 8px;
        }
    }
    
    .container:hover {
        background: linear-gradient(228.84deg, variables.$secondary-color 0%, variables.$primary-color 100%);
    }

    .container:hover svg.icon {
        path {
            fill: white !important;
        }
    }
}