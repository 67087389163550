@use './../../../assets/styles/variables';

.section-header {
    width: 100%;
    justify-content: center;

    p {
        margin: 0;
        padding: 0;
    }

    .section-name {
        font-family: "Montserrat Medium";
        font-size: 16px;
        color: variables.$primary-color;
        margin-bottom: 5px;
        text-align: center;
    }

    .callout {
        font-family: "Montserrat Medium";
        font-size: 36px;
        margin-bottom: 44px;
        text-align: center;
    }

    .description-container {
        width: 100%;
        margin-bottom: 20px;

        .description {
            font-family: "Montserrat";
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
            text-align: center;
        }
    }

    .dark {        
        color: variables.$dark-gray;
    }

    .light {        
        color: white;
    }
}