@use '../../../../assets/styles/variables';

.job-profile-grid {    
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 46px;
    row-gap: 56;
}


/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {

    .job-profile-grid { 
        grid-template-columns: repeat(2, 1fr);
    }
}

/* For Mobile */
@media (max-width: variables.$mobile-breakpoint) {

    .job-profile-grid {
        grid-template-columns: 1fr;
    }
}