@use './../../../../assets/styles/variables';

.tab-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: variables.$fullTabBarHeight;
    z-index: 999;

    .inner-tab {
        width: 100%;
        height: 54px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border-top: variables.$light-gray solid 1px;

        .tab-items {
            display: flex;
            align-items: center;
            width: 50%;
            height: 100%;
        }

        .left {
            margin-right: 34px;
        }

        .right {
            margin-left: 34px;
        }
    }
    
    .main-tab-item {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background: linear-gradient(90deg, variables.$primary-color 0.44%, variables.$secondary-color 100%);
        border: variables.$gray solid 1px;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        transition: bottom 0.1s ease-out;

        img {
            width: 50px;
        }
    }

    .main-sel {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        bottom: 5px;
        transition: bottom 0.1s ease-in-out;
    }
}