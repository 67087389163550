@use '../../../assets/styles/variables';

section { 
    padding: 30px 30px 50px 30px;  
}

.mobile-container {

    .sub-container {
        margin-bottom: variables.$tabBarHeight;
    }
}