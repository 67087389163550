@use './../../../../../assets/styles/variables';

.menu-item {

    margin-left: 50px;
      
    span {
        font-size: 14px;
        color: white;
        text-decoration: none;
    }

    div {
          width: 100%;
          height: 4px;
          background-color: variables.$primary-color;
          border-radius: 2px;
          margin-top: 8px;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
      }
}

.selected {
    span {
        font-family: "Montserrat SemiBold";
    }

    div {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }
}

.dark {
    span {
        color: variables.$dark-gray;
    }
}


.menu-item:hover {
    cursor: pointer;
}


/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {
    .menu-item {
 
        span {
            font-size: 12px;
        }
    }
  }