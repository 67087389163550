@use './../../../../assets/styles/variables';


.default-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  height: variables.$header-height;
  padding: 0 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);

  .logo {
    height: 50px;
    opacity: 0;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
  }
}

.header-visible {
  background-color: white;
  transition: background-color 0.2s ease-in-out;
  -webkit-box-shadow: 0px -7px 28px -5px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px -7px 28px -5px rgba(0,0,0,0.2);
  box-shadow: 0px -7px 28px -5px rgba(0,0,0,0.2);  

  .logo {
    opacity: 1;
  }
}


/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {
  .header-visible {
    padding: 0 25px;
    height: variables.$small-header-height;

    .logo {
      height: 38px;
    }
  }
}