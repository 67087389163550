@import './colors';
@import './fonts';



$header-height: 100px;
$small-header-height: 60px;

$fullTabBarHeight: 74px;
$tabBarHeight: 44px;

$mobile-breakpoint: 768px;
$tablet-breakpoint: 1280px;
$max-desktop-breakpoint: 3000px;