@use '../../../assets/styles/variables';

.splash {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    background: linear-gradient(120deg, variables.$primary-color, variables.$secondary-color);
    background-size: 200% 200%;

    -webkit-animation: GradientAnimation 4s ease infinite;
    -moz-animation: GradientAnimation 4s ease infinite;
    -o-animation: GradientAnimation 4s ease infinite;
    animation: GradientAnimation 4s ease infinite;

    img {
        width: 15%;
    }
}

.fade-enter {
    opacity: 0;
    background-color: red;
  }
  
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
  
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

@-webkit-keyframes GradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes GradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes GradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes GradientAnimation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@media (max-width: variables.$mobile-breakpoint) {

  .splash { 
    img {
        width: 45%;
    }
  }
}

@media (max-width: variables.$tablet-breakpoint) {

  .splash { 
    img {
        width: 35%;
    }
  }
}
