@use '../../../assets/styles/variables';

.main-container {    
    section {
        padding: 40px 74px 80px 74px;
    }
}


/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {

    .main-container {
        section {   
            padding: 40px 44px 80px 44px;      
        }
    }
}

@media (min-width: variables.$max-desktop-breakpoint) {
    .main-container {
        section {
            padding-left: 20%;
            padding-right: 20%;
        }
    }
}