@use './../../../../assets/styles/variables';

.progress-grid {
    width: 100%;

    .section-title-container {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 46px;
        row-gap: 60;
    }
}

/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {

    .progress-grid {         

        .grid-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

/* For Mobile */
@media (max-width: variables.$mobile-breakpoint) {

    .progress-grid {

        .grid-container {
            grid-template-columns: 1fr;
        }
    }
}