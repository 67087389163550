@use './../../../../../assets/styles/variables';

.tab-item {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    svg.icon {
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
        
        path {
            fill: variables.$gray!important;
            stroke: variables.$gray !important;
        }
    }

    span {
        font-size: 10px;
        color: variables.$gray;
        
    }
}

.tab-sel {
    svg.icon {
        width: 22px;
        height: 22px;
        
        path {
            fill: variables.$primary-color !important;
            stroke: variables.$primary-color !important;
        }
    }

    span {
        font-size: 11px;
        font-family: "Montserrat Medium";
        color: variables.$dark-gray;
        
    }    
}