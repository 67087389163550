@use './../../../../../assets/styles/variables';

.progress-bar {
    width: 100%;
       

    .bar-container {
        width: 100%;
        height: 8px;
        border-radius: 8px;
        background-color: variables.$light-gray;
    }

    .skill {      
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        span {
            font-size: 14px;
            color: variables.$dark-gray;
        }

        .description {
            font-family: "Montserrat Medium";
        }
    }

    .progress {
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(90deg, variables.$primary-color 0.44%, variables.$secondary-color 100%);
        transition: width 0.3s ease-in-out 5s;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }
}