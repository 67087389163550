@use './../../../../assets/styles/variables';

.social-media-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: variables.$light-gray-semitrans;
    border-radius: 50%;
    margin-left: 25px;
    margin-right: 25px;


    .icon {
        width: 30px;
        height: 30px;
        
        path {
            fill: white;
        }
    }
}

.social-media-button:hover {
    background-color: variables.$primary-color;
    cursor: pointer;
}