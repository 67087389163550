@use './../../../assets/styles/variables';

.about-me {    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;    
    background-color: white;

    .section-header-container {
        width: 70%;
    }
}

/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {

    .about-me {         
        .section-header-container {
            width: 100%;
        }
    }
}