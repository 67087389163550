@use '../../../assets/styles/variables';

.home {
    padding: 20px;
    height: 100vh;
    background-image: url('./../../../assets/images/backgrounds/home-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    

    #particles {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;           

        .photo {
            width: 188px;
            height: 188px;
            border-radius: 50%;
            background-color: variables.$secondary-color;
            padding: 4px;
            align-items: center;
            margin-bottom: 44px;
            z-index: 998;            
            
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);    
                object-fit: cover;
            }
        }

        .photo:hover {        
            img {
                -webkit-filter: none;
                filter: none; 
            }
        }

        h1 {
            font-size: 50px;
            font-family: "Montserrat Medium";
            text-align: center;
            color: white;
            padding: 0;
            margin: 0;
        }
        h2 {
            font-size: 28px;
            font-family: "Montserrat";
            color: variables.$primary-color;

        }
    }
}


/* For Tablet */
@media (max-width: variables.$tablet-breakpoint) {
    .home {
        .content {
            .photo {
                width: 158px;
                height: 158px;
                margin-bottom: 54px;
            }

            h1 {
                font-size: 42px;
            }
        }
    }
}

/* For Mobile */
@media (max-width: variables.$mobile-breakpoint) {
    .home {
        height: calc(100vh - variables.$tabBarHeight);

        .content {
            .photo {
                width: 144px;
                height: 144px;
            }
            
            h1 {
                font-size: 32px;
            }

            h2 {
                font-size: 24px;
            }
        }
    }
}